import '../js/sentry'

import { h, hydrate } from 'preact'

import { BlogApp } from '../js/app'

export default async function render(pageContext) {
  hydrate(
    <BlogApp pageContext={pageContext} />,
    document.querySelector('body'),
  )
}
